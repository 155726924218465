<template>
  <div style="height: 100%" class="_container">
    <headerr :customBack="true" @back="$router.go(-1)" class="_header">
      Anagrafica paziente
    </headerr>
    <v-card
      elevation="0"
      height="100%"
      class="d-flex flex-column"
      style="overflow-y: auto"
    >
      <v-card-text style="height: fit-content">
        <v-row align="center" justify="center" class="ma-2">
          <v-col cols="10" class="pa-0">
            <v-text-field
              placeholder="Inserisci il codice nosologico"
              class="mb-5"
              label="Codice nosologico"
              hide-details
              outlined
              dense
              v-model="$v.dataSurgery.nosological.$model"
              :error="$v.dataSurgery.nosological.$error"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="4" class="pa-0">
              <v-card-text>Nome</v-card-text>
            </v-col> -->
          <v-col cols="10" class="pa-0">
            <v-text-field
              placeholder="Inserisci il nome"
              class="mb-5"
              label="Nome paziente"
              hide-details
              outlined
              dense
              v-model="$v.dataSurgery.firstName.$model"
              :error="$v.dataSurgery.firstName.$error"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="4" class="pa-0">
              <v-card-text>Cognome</v-card-text>
            </v-col> -->
          <v-col cols="10" class="pa-0">
            <v-text-field
              placeholder="Inserisci il cognome"
              class="mb-5"
              label="Cognome paziente"
              hide-details
              outlined
              dense
              v-model="$v.dataSurgery.lastName.$model"
              :error="$v.dataSurgery.lastName.$error"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="4" class="pa-0">
              <v-card-text>Data di nascita</v-card-text>
            </v-col> -->
          <v-col cols="10" class="pa-0 mb-5">
            <v-btn
              :color="dataValida ? 'primary' : 'error'"
              width="100%"
              @click="dataNascitaDialog = true"
            >
              {{
                dataSurgery.birth
                  ? "Data di nascita: " + formattaDatanascita
                  : "Inserisci la data di nascita"
              }}
            </v-btn>
          </v-col>
          <!-- <v-col cols="4" class="pa-0">
              <v-card-text>Genere</v-card-text>
            </v-col> -->
          <v-col cols="10" class="pa-0">
            <v-select
              :items="gender"
              class="mb-5"
              label="Genere"
              hide-details
              outlined
              dense
              v-model="$v.dataSurgery.gender.$model"
              :error="$v.dataSurgery.gender.$error"
            ></v-select>
          </v-col>
          <!-- <v-col cols="4" class="pa-0">
              <v-card-text>Specialità chirurgica</v-card-text>
            </v-col> -->
          <v-col cols="10" class="pa-0">
            <v-select
              :items="specialitaDisponibili"
              class="mb-5"
              label="Specialità chirurgica"
              hide-details
              outlined
              dense
              v-model="$v.dataSurgery.speciality.$model"
              :error="$v.dataSurgery.speciality.$error"
            ></v-select>
          </v-col>
          <!-- <v-col cols="4" class="pa-0">
              <v-card-text>Intervento</v-card-text>
            </v-col> -->
          <v-col cols="10" class="pa-0 mb-5">
            <v-select
              color="primary"
              width="100%"
              :disabled="!dataSurgery.speciality"
              :items="interventiSpecialita"
              outlined
              dense
              v-model="$v.dataSurgery.surgery.$model"
              :error="$v.dataSurgery.surgery.$error"
              label="Intervento"
              item-text="nome"
              item-value="id"
            >
              {{
                dataSurgery.surgery
                  ? formattaIntervento(dataSurgery.surgery)
                  : "Seleziona un intervento"
              }}
            </v-select>
          </v-col>
          <!-- <v-col cols="4" class="pa-0">
              <v-card-text>Note</v-card-text>
            </v-col> -->
          <v-col cols="10" class="pa-0">
            <v-textarea
              placeholder="Inserisci le note"
              auto-grow
              clearable
              class="mb-5"
              label="Note"
              hide-details
              outlined
              dense
              v-model="$v.dataSurgery.inputNotes.$model"
              :error="$v.dataSurgery.inputNotes.$error"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-col sm="10" lg="4" class="d-flex pa-0">
          <app-firma
            @check="checkData"
            @save="(event) => save(event)"
            :valutatoreInput="valutatore"
            :numberInput="number"
            :loading="loading"
            :operazione="false"
          ></app-firma>
        </v-col>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dataNascitaDialog" max-width="300" persistent>
      <v-date-picker
        v-model="dataNascita"
        :error="$v.dataSurgery.birth.$error"
        color="primary"
        style="border-bottom-right-radius: 0; border-bottom-left-radius: 0"
      ></v-date-picker>
      <v-btn
        width="100%"
        color="primary"
        style="border-top-right-radius: 0; border-top-left-radius: 0"
        @click="dataNascitaDialog = false"
      >
        Ok
      </v-btn>
    </v-dialog>
    <v-dialog v-model="interventoDialog" max-width="700">
      <v-card class="pb-1">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Seleziona l'intervento</v-toolbar-title>
        </v-toolbar>
        <v-col cols="12" class="pa-2">
          <v-text-field
            prepend-icon="mdi-magnify"
            v-model="search"
            label="Cerca intervento"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          v-for="(singoloIntervento, index) in interventi"
          :key="index"
        >
          <v-btn
            width="100%"
            elevation="4"
            @click="
              dataSurgery.surgery = singoloIntervento.id;
              interventoDialog = false;
            "
            :error="$v.dataSurgery.surgery.$error"
            style="font-size: 11px"
          >
            {{ singoloIntervento.nome }}
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errorDialog" max-width="300">
      <v-card class="pb-1">
        <v-toolbar color="error" dark flat>
          <v-toolbar-title>Attenzione</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-3">{{ errorText }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" @click="errorDialog = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="verifyError" color="error" :timeout="3000">
      <h2 style="text-align: center">{{ errorText }}</h2>
    </v-snackbar>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import Firma from "../../components/Firma.vue";
import { database, functions } from "@/plugins/firebase";

export default {
  components: {
    "app-firma": Firma
  },
  data() {
    return {
      dataNascitaDialog: false,
      dataValida: true,
      dataNascita: null,
      interventoDialog: false,
      search: "",

      errorDialog: false,
      errorText: "",

      firma: null,
      valutatore: null,
      id: null,
      loading: false,

      overlay: false,
      number: "",
      maxLength: 5,

      invalidPin: false,
      errorStorage: false,

      dataSurgery: {
        nosological: "",
        firstName: "",
        lastName: "",
        birth: null,
        gender: null,
        speciality: null,
        surgery: null,
        inputNotes: ""
      },

      dataToLoad: null,
      cont: true
    };
  },
  validations() {
    return {
      dataSurgery: {
        nosological: { required },
        firstName: { required },
        lastName: { required },
        birth: { required },
        gender: { required },
        speciality: { required },
        surgery: { required },
        inputNotes: {}
      }
    };
  },
  methods: {
    async checkPin() {
      // console.log("in check pin: ", this.dataToLoad);
      this.errorStorage = false;
      this.invalidPin = false;
      this.loading = true;
      try {
        if (this.id === null) {
          await this.$store.dispatch("pazienti/aggiungiPaziente", {
            id: this.id,
            payload: this.dataToLoad,
            operatore: this.valutatore,
            pin: this.number
          });
        } else {
          this.dataToLoad.firstName = this.dataToLoad.firstName.toUpperCase();
          this.dataToLoad.lastName = this.dataToLoad.lastName.toUpperCase();
          await this.$store.dispatch("pazienti/modificaPaziente", {
            id: this.id,
            payload: this.dataToLoad,
            operatore: this.valutatore,
            pin: this.number
          });
        }
      } catch (error) {
        console.log(error);
        if (error.message === "NotAuthorizedError - Pin not valid") {
          this.errorText = "PIN errato";
          this.invalidPin = true;
        } else {
          this.errorText = "Errore durante il caricamento dei dati";
          this.errorStorage = true;
        }
      } finally {
        this.loading = false;
      }
      if (!this.invalidPin && !this.errorStorage) {
        this.overlay = false;
        this.$router.go(-1);
      }
    },
    esci() {
      this.dialog = false;
    },
    visibile(nome) {
      const { search } = this;
      if (search === "") return true;
    },
    aggiungi() {
      this.dataSurgery = {
        nosological: "",
        firstName: "",
        lastName: "",
        birth: null,
        gender: null,
        speciality: null,
        surgery: null,
        inputNotes: ""
      };
    },
    modifica({
      nosological,
      firstName,
      lastName,
      birth,
      gender,
      speciality,
      surgery,
      inputNotes
    }) {
      this.dataSurgery = {
        nosological,
        firstName,
        lastName,
        birth,
        gender,
        speciality,
        surgery,
        inputNotes
      };
      this.$v.dataSurgery.$reset();
    },
    checkData(callback) {
      const { $touch, $invalid } = this.$v.dataSurgery;
      if (!this.dataValida) {
        this.errorText = "Inserisci una data di nascita valida";
        this.errorDialog = true;
        this.cont = false;
        callback(false);
      } else {
        $touch();
        if (!$invalid) {
          callback(true);
        } else {
          if (this.dataSurgery.birth === null) {
            this.dataValida = false;
          }
          this.errorText = "Compila tutti i campi obbligatori";
          this.errorDialog = true;
          callback(false);
        }
      }
    },
    save(event) {
      const { dataSurgery } = this;
      this.cont = true;
      const val = this.formattaValutatore(event.valutatore);
      // console.log("modified by: ", val);
      // $touch();
      // console.log("status: ", this.status);
      // if (!$invalid) {
      this.dataToLoad = {
        ...dataSurgery,
        status: this.status[0].value,
        modifyAt: new Date(),
        modifyBy: event.valutatore,
        deleted: false,
        warnings: this.addWarnings(dataSurgery.surgery),
        accessori: this.addEquipments(dataSurgery.surgery),
        // firma: event.firma
      };
      // console.log("data to load: ", this.dataToLoad);
      this.valutatore = event.valutatore;
      this.number = event.number;
      this.checkPin();
      // this.overlay = true;
      // } else {
      //   this.errorText = "Compila tutti i campi obbligatori";
      //   this.errorDialog = true;
      // }
    },
    formattaValutatore(id) {
      // console.log("ID", id);
      // console.log("valutatori: ", this.operatori);
      const valutatore = this.operatori.find(
        (valutatore) => valutatore.id === id
      );

      if (valutatore == null || valutatore == undefined) {
        return "Valutatore non trovato";
      }

      return `${valutatore.nome} ${valutatore.cognome}`;
    },
    addWarnings(surgery) {
      const configurazioneIntervento =
        this.$store.getters["categorie/intervento"](surgery).configurazione;
      return this.$store.getters["categorie/configurazione"](
        configurazioneIntervento
      ).warnings.map((warn) => ({
        selezionato: false,
        warning: warn
      }));
    },
    addEquipments(surgery) {
      var usedEquipements = [];
      const configurazioneIntervento =
        this.$store.getters["categorie/intervento"](surgery).configurazione;
      const configurazione = this.$store.getters["categorie/configurazione"](
        configurazioneIntervento
      );
      configurazione.configurazioneScenaAccessori.forEach((acc) => {
        if (!usedEquipements.find((e) => e.idFirebase === acc.originalName)) {
          usedEquipements.push(
            this.listaAccessoriCompleti.find(
              (e) => e.idFirebase === acc.originalName
            )
          );
        }
      });
      configurazione.configurazioneScenaPosizionamento.forEach((acc) => {
        if (!usedEquipements.find((e) => e.idFirebase === acc.originalName)) {
          usedEquipements.push(
            this.listaAccessoriCompleti.find(
              (e) => e.idFirebase === acc.originalName
            )
          );
        }
      });
      return usedEquipements.map((e) => {
        return {
          selezionato: false,
          nome: e.nome,
          codice: e.codice,
          id: e.idFirebase
        };
      });
    }
  },
  watch: {
    dataNascita() {
      const dataOdierna = new Date();
      const dataNascita = new Date(this.dataNascita);
      let eta = dataOdierna.getFullYear() - dataNascita.getFullYear();

      if (dataOdierna.getMonth() < dataNascita.getMonth()) {
        eta--;
      } else if (dataOdierna.getMonth() === dataNascita.getMonth()) {
        if (dataOdierna.getDate() < dataNascita.getDate()) {
          eta--;
        }
      }
      this.dataSurgery.birth = this.dataNascita;
      if (eta < 0) {
        this.dataValida = false;
      } else {
        this.dataValida = true;
      }
    },
    configurazione() {
      this.$store.getters["categorie/configurazione"](
        this.dataSurgery.surgery.configurazione
      );
    }
  },
  computed: {
    formattaDatanascita() {
      const dateArray = this.dataSurgery.birth.split("-");
      return [dateArray[2], dateArray[1], dateArray[0]].join("/");
    },
    ...mapGetters({
      gender: "liste/gender",
      specialità: "liste/specialità",
      interventi: "categorie/interventi",
      formattaIntervento: "categorie/formattaIntervento",
      status: "liste/status",
      operatori: "operatori/operatori"
    }),
    listaAccessoriCompleti() {
      return this.$store.getters["categorie/listaAccessoriCompleti"];
    },
    specialitaDisponibili() {
      const specialitaStruttura = [];
      this.interventi.forEach((intervento) => {
        if (!specialitaStruttura.includes(intervento.specialita)) {
          specialitaStruttura.push(intervento.specialita);
        }
      });
      return specialitaStruttura;
    },
    interventiSpecialita() {
      return this.interventi.map((intervento) => {
        if (intervento.specialita === this.dataSurgery.speciality) {
          return {
            nome: this.formattaIntervento(intervento.id),
            id: intervento.id
          };
        }
      });
    },
    verifyError: {
      get() {
        if (this.invalidPin || this.errorStorage) {
          return true;
        } else {
          return false;
        }
      },
      set() {
        return false;
      }
    }
  },
  created() {
    if (this.$route.params.crea) {
      this.aggiungi();
    } else {
      this.modifica(this.$route.params.item);
      this.id = this.$route.params.item.id;
      // console.log("id: ", this.id);
      const created = this.$route.params.item.modifiedBy;
      // console.log("id: ", created, "nome: ", this.formattaValutatore(created));
    }
  }
};
</script>

<style>
.form-pazienti {
  overflow-y: hidden;
  overflow-x: hidden;
}
</style>